<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="title-content-sub">
        <div class="title">合同台账上传</div>
        <ProgressIndicator class="progressIndicator" :steps="steps"
                           :current="currentStepIndex"></ProgressIndicator>
      </div>
    </div>

    <div class="section-box">
      <div>
        <div class="section-head">
          <div>请选择需要导入的文件</div>
          <div class="section-head-right">
            <a
              href="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E5%90%88%E5%90%8C%E5%8F%B0%E8%B4%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
              class="btn-download"
              download
            >点击下载《合同台账导入模板》</a
            >
          </div>
        </div>

        <div >
          <div
            class="fix-flexsL"

          >

            <YinHuaShuiUploaderComponent
              @upload="onUpload"
              @finish="handleUploadFinish"
              @start="handleUploadStart"
            ></YinHuaShuiUploaderComponent>
            <div class="desc">
              <div>使用说明：</div>
              <div>①表格首行请严格按照模板，便于系统识别。</div>
              <div>②请确保上传表格中中合同金额为“数值”格式，且单元格格式为“数值”；合同签订日期为“日期”格式。</div>
              <div>③为便于统计，请统一“合同金额”货币单位。</div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import ProgressIndicator from "@/pages/components/ProgressIndicator";
import YinHuaShuiUploaderComponent from "./components/YinHuaShuiUploaderComponent"
export default {
  name: "YinhuaShuiUploader",
  components: {
    BreadCrumb,
    ProgressIndicator,
    YinHuaShuiUploaderComponent
  },
  data() {
    return {
      steps: [
        "上传文件",
        "分类数据",
        "分类结果"
      ],
      currentStepIndex: 0
    }
  },
  methods:{
    handleUploadStart(){
      this.currentStepIndex = 1;
    },
    handleUploadFinish(){
      this.currentStepIndex = 0;
    },
    onUpload(programId){
      this.$router.push({
        name:"util-yinHuaShui",
        params:{
          programId
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

    .title-content-sub {
      margin: 8px 30px;
      display flex
      position relative
      flex-direction row
      align-items center

      .title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }

      .progressIndicator {
        position absolute;
        left 50%
        top 50%
        transform translate(-50%, -50%)
      }

    }
  }

  .section-box {
    .section-head {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 54px;
      background: #fff;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 24px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8)

      .section-head-right {
        display flex;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(47, 84, 235, 1);
      }
    }

  }
  .desc{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    margin-top 25px
    margin-bottom 50px
  }
}
</style>
