<template>
  <div class="dragzone-wrapper" >
    <div
      class="uploader"
      id="dropz"
      v-loading="loading"
      element-loading-text="文件正在上传中,请稍后"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
    <div class="uploader-bg">
      <div class="dz-message">
        <div class="drog_imgx">
          <img class="drog_img" src="@/assets/test/Icon@2x.png" alt />
        </div>
        <div class="drog_text" >
          <span>点击上传，或将文件拖拽到此处</span>
        </div>
        <div class="acceptable_text">
          支持：Excel/HTML/文件夹
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import Dropzone from "@/common/js/dropzone.js";
import {uploadContExcel, uploadForTestUrl, uploadForTestUrlTag} from "@/api/table";
import { mapState } from "vuex";

export default {
  name:'YinHuaShuiUploaderComponent',
  async mounted() {
    const _this = this;
    this.myDropzone = new Dropzone("#dropz", {
      url: uploadContExcel(), // 文件提交地址
      method: "post", // 也可用put
      paramName: "files", // 要传输的文件参数的名称
      maxFiles: 2000, // 一次性上传的文件数量上限
      maxFilesize: 15, // 文件大小，单位：MB
      uploadMultiple: true, //如果您将选项 uploadMultiple设置为true，则Dropzone将附加[]到名称。
      acceptedFiles: ".xlsx,.xls,.html,.htm", // 上传的类型
      addRemoveLinks: true,
      parallelUploads: 1, // 一次上传的文件数量
      //previewsContainer:"#preview", // 上传图片的预览窗口
      //dictDefaultMessage: "拖动文件至此或者点击上传",
      dictMaxFilesExceeded: "您最多只能上传35个文件！",
      dictResponseError: "文件上传失败!",
      dictInvalidFileType: "文件类型只能是*.xlsx,*.xls,*.html,*.htm",
      dictFallbackMessage: "浏览器不受支持",
      dictFileTooBig: "文件过大上传文件最大支持.",
      dictRemoveLinks: "删除",
      dictCancelUpload: "取消",
      headers:{
        Authorization:localStorage.getItem("authorization")
      },
      //dictRemoveFile: "删除", //删除按钮显示的文字
      init: function () {


        this.on("processingmultiple", function (file) {
          _this.loading = true;
          _this.$emit("start")
          // 上传文件时触发的事件
        });
        this.on("success", (file, data) => {
          if(data.code==200){
            _this.$emit("upload",data.data)
          }

          // if (data.data.errorData.length == 0) {
          //   setTimeout(() => {
          //     _this.$message.success(`${file.name} 上传成功`);
          //   }, _this.time);
          //   _this.time = _this.time += 100;
          // }
          // 上传成功触发的事件
        });
        // this.on("errormultiple", function (file, data) {
        //   //console.log(data);
        //   let msg = `${file.name} 上传失败`;
        //   if (data) {
        //     _this.errors.push(data);
        //   }

        //   // setTimeout(() => {
        //   //   _this.$message.error(`${file.name} 上传失败`);
        //   // }, _this.time);
        //   // _this.time = _this.time += 100;
        // });
        this.on("error", function (file, data) {
          let msg = `上传失败`;
          //如果有错误的表格则弹出弹出框
          _this.$message.error(msg);
        });
        this.on("removedfile", function (file) {
          // 删除文件时触发的方法
        });
        this.on("dragenter", function () {
          this.element.classList.add("drag-hover");
        });
        this.on("dragleave", function () {
          this.element.classList.remove("drag-hover");
        });
        this.on("complete", function (file) {
          this.removeFile(file);
        });
        // 上传结束之后
        this.on("queuecomplete", function (file) {
          // 上传结束之后，关闭进度，将上传成功的数量置为0
          _this.loading = false

          _this.$emit("finish")

        });
        // addedfile
        this.on("addedfiles", function (file) {
          _this.totalOver = file.length;
          _this.$emit("changeLength", {
            length: file.length,
            status: _this.statusNumber,
          });
          _this.statusLength = file.length;
        });
      },
    });
  },
  destroyed() {
    this.myDropzone.off();
    this.myDropzone.destroy();
  },
  data: function () {
    return {
      myDropzone: null,
      loading: false,

    };
  },
};
</script>

  <style lang="stylus" scoped>
  >>> .dz-preview {
    display: none !important;
  }

  .dragzone-wrapper {
    position: relative;
    width: 100%;
    height: 415px;
    background: rgba(47, 84, 235, 0.1);
    border-radius: 4px;
    padding: 0px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dz-message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      // height: 98px;
      .drog_img {
        width: 108px;
        flex-grow: 0;
        margin-bottom: 16px;
      }

      .right-box {
        text-align: left;
        margin-left: 48px;

        .drog_text {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(47, 84, 235, 1);
          line-height: 22px;
          margin-bottom: 4px;

          span {
            color: #2f54eb;
          }
        }

        .acceptable_text {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.43);
          line-height: 22px;
        }
      }
    }

    .uploader {
      border: 1px dashed rgba(47, 84, 235, 1);
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      right: 0px;
      bottom: 0;
    }

    .uploader.drag-hover {
      border: 1px solid rgba(47, 84, 235, 1);
    }
  }

  .drog_text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    margin-bottom: 4px;

    span {
      color: #2f54eb;
    }
  }

  .acceptable_text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.43);
  }

  .drog_imgx {
    width: 108px;
    height: 72px;
    background: transparent;
    margin-bottom: 16px;
  }

  .checkHandStyle {
    // width: 304px;
    height: 558px;
    margin-right: 32px;
  }

  .drog_texts {
    padding-top: 24px;
    padding-bottom: 8px;
  }
</style>
